<template>
    <div class="auth__content hero-static content content-full">
        <!-- Avatar -->
        <div class="py-30 px-5 text-center content-header__wrapper">
            <img class="content-header__logo" src="~assets/img/wikiworks-logo.png" height="32" width="194"
                 alt="Wikiworks">
        </div>
        <!-- END Avatar -->

        <!-- Unlock Content -->
        <div class="row justify-content-center px-5">
            <div class="col-sm-8 col-md-6 col-lg-6 d-flex justify-content-center autorization__wrapper">
                <div class="modal__block autorization__content">
                    <h3 class="autorization__title">Восстановление пароля</h3>
                    <div class="autorization__tab-content autorization__tab-content--restore">
                        <!-- Вкладка входа -->
                        <div v-if="!isSended" class="autorization__tab">
                            <!-- Форма восстановления пароля -->
                            <ValidationObserver tag="form" ref="observer" class="form" @submit.prevent="onSubmit">
                                <div class="form__content">
                                    <ValidationProvider
                                        tag="div"
                                        class="form__group"
                                        :rules="`required|email`"
                                        vid="email"
                                        v-slot="{errors}"
                                    >
                                        <input
                                            type="text"
                                            class="form__control_auth email"
                                            :class="{'form__error':errors.length}"
                                            name="email"
                                            placeholder="E-mail"
                                            v-model="email"
                                        >
                                        <span class="v-error animated fadeInDown" v-if="errors.length">
                                            {{errors[0]}}
                                        </span>
                                        <!--{% if form.email.errors %}
                                        <div class="form__error">
                                            {% for error in form.email.errors %}
                                            {{ error }}
                                            {% endfor %}
                                        </div>
                                        {% endif %}-->
                                    </ValidationProvider>
                                    <div class="form__group form__group--top">
                                        <button type="submit" class="autorization__btn btn btn_first btn_first-fill">
                                            Восстановить пароль
                                        </button>
                                    </div>
                                    <div class="form__group">
                                        <router-link tag="a" to="/login"
                                                     class="autorization__btn btn btn_third btn_third-fill">Войти
                                        </router-link>
                                    </div>
                                </div>
                            </ValidationObserver>
                            <!-- Сообщение об отправке -->
                            <div class="autorization__send-message d-none">
                                <div>Восстановление пароля</div>
                                <div>Ссылка для восстановления пароля отправлена на ваш e-mail. Проверьте почту</div>
                            </div>
                        </div>
                        <div v-else class="autorization__tab">
                            <div class="autorization__mailing text-center">
                                <img
                                    height="70"
                                    width="90"
                                    alt="Mailing"
                                    src="~assets/img/mailing.png"
                                >
                            </div>

                            <div class="autorization__send-message text-center">
                                <div>
                                    На указанный адрес эл.почты был отправлен новый пароль для входа
                                </div>
                            </div>

                            <div class="form__group form__group--top form__btns">
                                <router-link
                                    tag="a"
                                    to="/login"
                                    data-display-target="login-form"
                                    class="autorization__btn btn btn_first btn_first-fill"
                                >
                                    Войти
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Unlock Content -->
    </div>
</template>

<script>
import session from '@/api/session'

export default {
    name: 'PasswordReset',
    data() {
        return {
            isSended: false,
            email: ''
        }
    },
    methods: {
        async onSubmit() {
            let isValid = await this.$refs.observer.validate();

            if (isValid) {
                try {
                    const request = await session.post(`/api/v1/password-recovery/`, {
                        email: this.email
                    });
                    this.isSended = true;
                } catch(error) {
                    if (error.response) {
                        console.log(error.response)
                        this.$refs.observer.setErrors(error.response.data);
                    } else {
                        this.$refs.observer.setErrors({
                            email: 'Произошла ошибка на сервере. Попробуйте позднее.'
                        });
                    }
                }
                this.isSending = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "auth";

    .modal__block {
        display: flex;
        flex-direction: column;
    }

    .autorization {
        &__tab {
            display: flex;
            flex-direction: column;
            min-height: 100%;
            &-content {
                flex-grow:  1;
            }
        }
    }

    .form {
        &__btns {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
</style>
